import "./App.css";

function App() {
  return (
    <div className="main">
      <h1>
        Welcome to my crappy homepage <strike>but it'll get better.</strike>
      </h1>
      <p>
        I'm [ref], or whoever you know me by. I host a variety of content here.
      </p>
      <h3>
        <em>
          For abuse complaints regarding any of my services, please email <a href="mailto:abuse@ermm.us">abuse@ermm.us</a>.
        </em>
      </h3>
      <h5>
        <em> For other inquiries, contact me at <a href="mailto:ref@ermm.us">ref@ermm.us</a>.</em>
      </h5>

      <p>
        I plan to add more services and cool features soon. Here's what I have available right now:
      </p>
      <ul>
        <li><a href="https://pomf.ermm.us">Pomf</a></li>
        <li><a href="https://movies.ermm.us">Movies</a></li>
        <li><a href="https://cdn.ermm.us">CDN (WIP)</a></li>
        <li><a href="https://inv.sayt.in">Invidious</a></li>
        <li><a href="https://s.ermm.us">Link Shortener</a></li>
        <li><a href="https://git.ermm.us">Git</a></li>
        <li><a href="https://box.sayt.in">Mail Server</a></li>
      </ul>

      <footer>
        &copy; 2023 [ref]
      </footer>
    </div>
  );
}

export default App;